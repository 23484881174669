// React
import { useState, useEffect, useRef } from 'react';

// bootstrap
import Table from "react-bootstrap/Table";
import Container from 'react-bootstrap/Container';
import "bootstrap/dist/css/bootstrap.css";
import { useSnackbar } from 'notistack';
// MUI
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { lightBlue } from '@mui/material/colors';
// import ConstructionItemIcon from '@mui/icons-material/Apartment';
import ConstructionItemIcon from '@mui/icons-material/Widgets';
// import GridOnIcon from '@mui/icons-material/GridOn';
// import ApartmentIcon from '@mui/icons-material/Apartment';
// import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
// import AppsIcon from '@mui/icons-material/Apps';
// import ConstructionIcon from '@mui/icons-material/Construction';
// import CorporateFareIcon from '@mui/icons-material/CorporateFare';
// import LocationCityIcon from '@mui/icons-material/LocationCity';
// import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
// import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import RenameIcon from '@mui/icons-material/DriveFileRenameOutline';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';

// MobX
import { observer } from 'mobx-react-lite';

// Blockly
import Blockly from 'blockly/core';

// VMT components
import VmtTree from 'components/VmtTree';
import DialogModal from 'components/Modals/DialogModal';
import { CRUD, TreeActions, ContextMenuItems, InitXmls, NodeType } from 'utils/const';

// CSS
import { css } from "aphrodite";

// VMT styles
import styles from '../Styles';

// VMT stores
import { constructionDataStore, selectedConstructionInfoStore, useStore } from 'stores';
import { useAppContext } from 'app-context';
import { useContextMenu } from 'utils/hooks/useContextMenu';

const IconColor = lightBlue[500];

export const ConstructionTreeComponent = observer((/*props*/) => {
    console.log('<ConstructionTreeComponent>');
    const { api, store } = useAppContext();
    const { contextMenu, anchorPosition, handleContextMenu, handleClose } = useContextMenu();
    const treeRef = useRef();
    const [focusedItem, setFocusedItem] = useState();
    const [selectedItems, setSelectedItems] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalData, setDeleteModalData] = useState([]);
    const [itemListToDelete, setItemListToDelete] = useState(<></>);
    const [currentNode, setCurrentNode] = useState(null);

    const [dataToProcess, setDataToProcess] = useStore(constructionDataStore);
    const [constructionInfo, setConstructionInfo] = useStore(selectedConstructionInfoStore);

    const { enqueueSnackbar/*, closeSnackbar*/ } = useSnackbar();

    const openDeleteModal = () => setShowDeleteModal(true);
    const closeDeleteModal = () => setShowDeleteModal(false);

    const apiCreateConstructionTreeNode = async (treeNodeJson) => {
        const node = await api.constructionTree.createEntry(treeNodeJson);
        console.log('New node', node);
        treeRef.current.startRenamingItem(node.id);
    };

    const apiConstructionsCRUD = async (action, constrId, treeNode, dataToUpdate = null) => {
        switch (action) {
            case CRUD.Create:
                console.log('constructions.create');
                const newConstruction = await api.construction.create(dataToUpdate);
                if (newConstruction) {
                    // add construction to the construction tree directory
                    const newTreeNodeItem = {
                        parent_id: currentNode.id,
                        node_type: NodeType.Item,
                        text: newConstruction.workspace_name,
                        data: {
                            reference_id: newConstruction.id,
                        }
                    };
                    console.log(newTreeNodeItem);
                    apiCreateConstructionTreeNode(newTreeNodeItem);
                    enqueueSnackbar('Construction was successfully created', { variant: 'success', persist: false });
                }
                else {
                    enqueueSnackbar('Could not create construction', { variant: 'error', persist: true });
                }
                break;
            case CRUD.Update:
                console.log('constructions.update');
                const updatedConstruction = await api.construction.update(constrId, dataToUpdate);
                if (!updatedConstruction) {
                    enqueueSnackbar('Could not update construction', { variant: 'error', persist: true });
                }
                break;
            default:
                break;
        }
    };

    const apiUpdateConstructionTree = async (action, id, dataToUpdate) => {
        const prevNodeText = store.constructionTree.nodesById.get(id).text;
        const node = await api.constructionTree.update(id, dataToUpdate);
        if (node) {
            console.log('api.constructionTree.update', node);
            if (node.node_type === NodeType.Item && dataToUpdate?.text !== prevNodeText) {
                // update Constrcution record
                const constructionId = node.data.reference_id;
                apiConstructionsCRUD(CRUD.Update, constructionId, null, { workspace_name: dataToUpdate.text });
            }
        }
        else {
            enqueueSnackbar('Could not update node', { variant: 'error', persist: true });
        }
    };

    const onPrimaryAction = (item/*, treeId*/) => {
        console.log('onPrimaryAction');
        console.log(item);
        console.log('----------------');

        if (item.node_type === NodeType.Item) {
            console.log(`Selected constr. id: ${item.data.reference_id}`);
            const constructionPath = 'N/A'; //getConstructionPath(itemData);
            setConstructionInfo({ id: item.data.reference_id, path: constructionPath });
        }
        else {
            console.log(`Selected constr. id: NULL`);
            setConstructionInfo(null);
        }
        setCurrentNode(item);
    };


    const getAllChildItems = (parentNode) => {
        let childrenToDelete = []
        parentNode.children.forEach(nodeId => {
            const node = store.constructionTree.nodesById.get(nodeId);
            if (node) {
                childrenToDelete.push(node);
                if (node.children.length > 0) {
                    const subNodes = getAllChildItems(node);
                    childrenToDelete.push(...subNodes);
                }
            }
        });
        return childrenToDelete;
    };

    const onTreeChange = (treeItem, action) => {
        console.log('onTreeChange: ' + action);
        console.log(treeItem);
        console.log('----------------');

        switch (action) {
            case TreeActions.Rename:
                apiUpdateConstructionTree(TreeActions.Rename, treeItem.id, { text: treeItem.text });
                break;
            case TreeActions.Move:
                // change parent_id
                apiUpdateConstructionTree(TreeActions.Move, treeItem.id, { parent_id: treeItem.parent_id });
                break;
            case TreeActions.Delete:
                // prepare list of item to delete
                const childrenToDelete = [];
                console.log('selectedItems', selectedItems);
                const treeItemsToDelete = selectedItems.length <= 1 ? [focusedItem.id] : selectedItems;
                treeItemsToDelete.forEach(nodeId => {
                    childrenToDelete.push(...getAllChildItems(store.constructionTree.nodesById.get(nodeId)));
                });
                console.log(childrenToDelete);
                let itemIdsToDelete = [...treeItemsToDelete, ...childrenToDelete.map(item => item.id)];
                itemIdsToDelete = [...new Set(itemIdsToDelete)];
                console.log(itemIdsToDelete);

                const itemsToDelete = itemIdsToDelete.map(itemId => store.constructionTree.nodesById.get(itemId));
                const itemsToDeleteJSX = itemsToDelete.map(item => {
                    return (
                        <tr key={item.id} style={{ fontWeight: item.node_type === NodeType.Item ? 'bold' : '' }}>
                            <td>{item.text}</td>
                            <td>{item.node_type === NodeType.Node ? 'Node' : 'Construction'}</td>
                        </tr>
                    );
                });
                const tableJsx =
                    <>
                        Following items will be deleted
                        <Container className={css(styles.modalScrollingPane)}>
                            {/* variant="dark" */}
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {itemsToDeleteJSX}
                                </tbody>
                            </Table>
                        </Container>
                    </>;
                setItemListToDelete(tableJsx);
                setDeleteModalData(itemsToDelete);
                openDeleteModal();
                break;
            default:
                break;
        }
    };

    // data "processor"
    useEffect(() => {
        if (dataToProcess === null) return;
        console.log('Process data for constr. id:', constructionInfo);
        console.log(dataToProcess);
        if (constructionInfo === null) { // new constructon
            apiConstructionsCRUD(CRUD.Create, null, null, dataToProcess);
        }
        else { // update construction
            apiConstructionsCRUD(CRUD.Update, constructionInfo.id, null, dataToProcess);
        }
        // reset the data as it's been processed
        setDataToProcess(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataToProcess, constructionInfo/*, apiConstructionsCRUD,*/, setDataToProcess]);

    const modalHandleDelete = async () => {
        console.log('modalHandleDelete', deleteModalData);

        const nodeIdsToDelete = deleteModalData.map(node => node.id);
        const constrIdsToDelete = deleteModalData
            .filter(node => node.node_type === NodeType.Item)
            .map(item => item.data.reference_id);
        console.log('nodeIdsToDelete', nodeIdsToDelete);

        const deletedNodes = await api.constructionTree.delete(nodeIdsToDelete);
        console.log('constructionDir.delete', deletedNodes);
        if (deletedNodes.length !== 0) {
            // reset the current construction id if it was selected
            if (constructionInfo !== null && constrIdsToDelete.includes(constructionInfo.id)) {
                setConstructionInfo(null);
            }
            // clear the data to delete
            setDeleteModalData([]);
            setSelectedItems([]);
        }
        else {
            enqueueSnackbar('Could not update construction', { variant: 'error', persist: true });
        }
        closeDeleteModal();
    };

    const onMenuItemSelect = (menuItemText) => {
        return () => {
            handleClose();
            console.log('onMenuItemSelect', menuItemText);
            console.log(focusedItem);
            const treeNode = focusedItem;
            switch (menuItemText) {
                case ContextMenuItems.NewNode:
                    apiCreateConstructionTreeNode({
                        parent_id: treeNode.id,
                        node_type: NodeType.Node,
                        text: 'New Node #1'
                        // no data.reference_id
                    });
                    break;
                case ContextMenuItems.RenameNode:
                    treeRef.current.startRenamingItem(focusedItem.id);
                    break;
                case ContextMenuItems.DeleteNode:
                    onTreeChange(focusedItem, TreeActions.Delete);
                    break;
                case ContextMenuItems.NewConstruction:
                    var xml = Blockly.utils.xml.textToDom(InitXmls.WDIO('New Construction'));
                    // Create a headless workspace.
                    var headlessWorkspace = new Blockly.Workspace();
                    Blockly.Xml.domToWorkspace(xml, headlessWorkspace);
                    const xmlDom = Blockly.Xml.workspaceToDom(headlessWorkspace);
                    const blocks = headlessWorkspace.getTopBlocks(false);
                    let baseTestBlock = blocks.find(block => block.type === 'base_test');
                    const itemData = focusedItem;
                    setConstructionInfo(null);
                    setCurrentNode(itemData);
                    setDataToProcess({
                        workspace_id: baseTestBlock.id,
                        workspace_name: baseTestBlock.getFieldValue('TEST_NAME').trim(),
                        workspace_xml: Blockly.Xml.domToText(xmlDom),
                        bricks: [],
                        controls: []
                    });
                    break;
                case ContextMenuItems.NodeDetails:
                    break;
                default:
                    break;
            }
        }
    };

    const isNodeSelected = () => focusedItem?.node_type === NodeType.Node;

    return (
        <>
            <div
                onContextMenu={handleContextMenu}
            // style={{ cursor: 'context-menu' }}
            >
                <VmtTree
                    treeId={'vmt-constructions-tree'}
                    treeLabel={'ConstructionTree'}
                    // itemArray={store.constructionTree.nodes}
                    treeItems={store.constructionTree.treeItems}
                    onFocusItem={(item) => { console.log("onFocusItem", item); setFocusedItem(item); }}
                    onSelectItems={(items/*, treeId*/) => { setSelectedItems([...items]); }}
                    onDrop={(itemIds, targetItemId, position) => {
                        console.log('onDrop', itemIds, targetItemId, position);
                        api.constructionTree.moveNodes(itemIds, targetItemId, position);
                    }}
                    onRenameItem={(item, newName) => onTreeChange({ ...item, text: newName }, TreeActions.Rename)}
                    onPrimaryAction={onPrimaryAction}
                    onDeleteKeyPressed={onMenuItemSelect(ContextMenuItems.DeleteNode)}
                    ref={treeRef}
                    color={IconColor}
                    ItemIcon={ConstructionItemIcon}
                />
            </div >
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition}
            >
                {isNodeSelected()
                    &&
                    <MenuItem onClick={onMenuItemSelect(ContextMenuItems.NewNode)} dense>
                        <ListItemIcon>
                            <FolderIcon sx={{ color: IconColor }} />
                        </ListItemIcon>
                        <ListItemText>{ContextMenuItems.NewNode}</ListItemText>
                    </MenuItem>}
                {isNodeSelected()
                    &&
                    <MenuItem onClick={onMenuItemSelect(ContextMenuItems.NewConstruction)} dense>
                        <ListItemIcon>
                            <ConstructionItemIcon sx={{ color: IconColor }} />
                        </ListItemIcon>
                        <ListItemText>{ContextMenuItems.NewConstruction}</ListItemText>
                    </MenuItem>}
                <MenuItem onClick={onMenuItemSelect(ContextMenuItems.RenameNode)} dense>
                    <ListItemIcon>
                        <RenameIcon sx={{ color: IconColor }} />
                    </ListItemIcon>
                    <ListItemText>{ContextMenuItems.RenameNode}</ListItemText>
                </MenuItem>
                <MenuItem onClick={onMenuItemSelect(ContextMenuItems.DeleteNode)} dense>
                    <ListItemIcon>
                        <DeleteForeverIcon sx={{ color: IconColor }} />
                    </ListItemIcon>
                    <ListItemText>{ContextMenuItems.DeleteNode}</ListItemText>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={onMenuItemSelect(ContextMenuItems.NodeDetails)} dense disabled>
                    <ListItemIcon>
                        <InfoIcon sx={{ color: IconColor }} />
                    </ListItemIcon>
                    <ListItemText>{ContextMenuItems.NodeDetails}</ListItemText>
                </MenuItem>

            </Menu>
            {/* Delete constructions/nodes modal*/}
            <DialogModal
                title='Deleting...'
                show={showDeleteModal}
                handleClose={closeDeleteModal}
                primaryBtnLabel='Delete'
                onPrimary={modalHandleDelete}
            >
                {itemListToDelete}
                Are you sure?
            </DialogModal>
        </>
    );
});