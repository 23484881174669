// React
import { useRef, useState, useCallback } from 'react';
// MUI
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { lightGreen } from '@mui/material/colors';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FolderIcon from '@mui/icons-material/Folder';
import StreetItemIcon from '@mui/icons-material/EditRoadOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConstructionSubItemIcon from '@mui/icons-material/Widgets';
import InfoIcon from '@mui/icons-material/Info';

// MobX
import { observer } from 'mobx-react-lite';
// VMT components
import VmtTree from 'components/VmtTree';
import { ContextMenuItems, NodeType } from 'utils/const';
// VMT stores
import { useAppContext } from 'app-context';

import { useStreetApi } from './useStreetApi';
import { useContextMenu } from 'utils/hooks/useContextMenu';

const IconColor = lightGreen[500];

export const StreetsTree = observer(({ hide = false }) => {
    console.log('<StreetsTree/>');

    const [focusedItem, setFocusedItem] = useState();
    const [selectedItems, setSelectedItems] = useState([]);

    const treeRef = useRef();
    const { api, store } = useAppContext();
    const { contextMenu, anchorPosition, handleContextMenu, handleClose } = useContextMenu();
    const {
        apiCreateStreetTreeItem,
        apiCreateStreetTreeGroup,
        apiCreateStreetTreeNode,
        apiCreateStreetTreeSubItems,
        apiRenameStreetTreeItem,
        apiStreetTreeItemNodeType
    } = useStreetApi({ treeRef });

    const onNodeRename = async (item, newName) => {
        await apiRenameStreetTreeItem(item.id, newName);
    };

    const getAllChildItems = (parentNode) => {
        let childrenToDelete = []
        parentNode.children.forEach(nodeId => {
            const node = store.streetTree.nodesById.get(nodeId);
            if (node) {
                childrenToDelete.push(node);
                if (node.children.length > 0) {
                    const subNodes = getAllChildItems(node);
                    childrenToDelete.push(...subNodes);
                }
            }
        });
        return childrenToDelete;
    };

    const onMenuItemSelect = useCallback((menuItemText) => {
        return async () => {
            handleClose();
            console.log('onMenuItemSelect', menuItemText);
            console.log(focusedItem);
            switch (menuItemText) {
                case ContextMenuItems.NewNode:
                    treeRef.current.expandItem(focusedItem.id);
                    apiCreateStreetTreeNode(focusedItem.id);
                    break;
                case ContextMenuItems.NewGroup:
                    treeRef.current.expandItem(focusedItem.id);
                    apiCreateStreetTreeGroup(focusedItem.id);
                    break;
                case ContextMenuItems.RenameNode:
                    treeRef.current.startRenamingItem(focusedItem.id);
                    break;
                case ContextMenuItems.DeleteNode:
                    // prepare list of item to delete
                    const childrenToDelete = [];
                    console.log('selectedItems', selectedItems);
                    const treeItemsToDelete = selectedItems.length <= 1 ? [focusedItem.id] : selectedItems;
                    treeItemsToDelete.forEach(nodeId => {
                        childrenToDelete.push(...getAllChildItems(store.streetTree.nodesById.get(nodeId)));
                    });
                    console.log(childrenToDelete);
                    let itemIdsToDelete = [...treeItemsToDelete, ...childrenToDelete.map(item => item.id)];
                    itemIdsToDelete = [...new Set(itemIdsToDelete)];
                    console.log(itemIdsToDelete);

                    await api.streetTree.delete(itemIdsToDelete);
                    setSelectedItems([]);
                    // setFocusedItem(null);
                    break;
                case ContextMenuItems.NewStreet:
                    treeRef.current.expandItem(focusedItem.id);
                    apiCreateStreetTreeItem(focusedItem.id);
                    // treeRef.current.startRenamingItem(node.id);
                    break;
                case ContextMenuItems.NodeDetails:
                    break;
                default:
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [focusedItem, selectedItems]);

    const onDrop = async (itemIds, targetItemId, position) => {
        // console.log('!!!onDrop!!!', itemIds, targetItemId, position);
        const targetItem = store.streetTree.nodesById.get(targetItemId);
        // we dropped at the item i.e. want to add dependency
        if (targetItem.node_type === NodeType.SubItem) {
            // "convert" sub-item into node-sub-item
            await apiStreetTreeItemNodeType(targetItemId, NodeType.NodeSubItem);
        }
        treeRef.current.expandItem(targetItemId);
        await api.streetTree.moveNodes(itemIds, targetItemId, position);

        // check for the node-sub-items which become sub-items
        const nodeSubItems =
            Array
                .from(store.streetTree.nodesById.values())
                .filter(item => (item.node_type === NodeType.NodeSubItem) && item.children.length === 0);
        // "convert" node-sub-item into sub-item 
        if (nodeSubItems.length > 0) {
            nodeSubItems.forEach(async (item) => {
                await apiStreetTreeItemNodeType(item.id, NodeType.SubItem);
            })
        }

    };

    const getAllConstructionIds = (treeItems) => {
        const constructionIds = [];
        treeItems.forEach(item => {
            item.node_type === NodeType.Item && constructionIds.push(item.id);
            if (item.node_type === NodeType.Node && item.children.length > 0) {
                const childTreeItems = item.children.map(id => store.constructionTree.nodesById.get(id));
                constructionIds.push(...getAllConstructionIds(childTreeItems));
            }
        });
        // remove duplicate Ids
        return constructionIds.filter((id, index) => constructionIds.indexOf(id) === index);
    }

    // between tree envs. drop i.e. new items are dropped from another (construction) tree
    const onNewItemsDrop = async (newItems, targetItem, sourceTreeId) => {
        console.log('onNewItemsDrop', newItems, targetItem, sourceTreeId);

        // TODO: need to calculate newItems (i.e. "extract" children items) if there are Nodes/Folders in there
        const constructionIds = getAllConstructionIds(newItems);
        const constrTreeItems = constructionIds.map(id => store.constructionTree.nodesById.get(id));
        // console.log('constrTreeItems', constrTreeItems);
        treeRef.current.expandItem(focusedItem.id);
        if (targetItem.node_type === NodeType.Node) {
            // create a street first
            const newStreetEntry = await apiCreateStreetTreeItem(targetItem.id);
            // ... than add construction entry
            await apiCreateStreetTreeSubItems(newStreetEntry[0].id, constrTreeItems/*[0].text*/);
        }
        else if (targetItem.node_type === NodeType.NodeItem || targetItem.node_type === NodeType.Group) {
            // add construction entry
            await apiCreateStreetTreeSubItems(targetItem.id, constrTreeItems/*[0].text*/);
        }
    };

    const canDropAt = (items, target) => {
        // all the items must be of the same kind
        const isSubItems = items.filter(item => item.node_type === NodeType.SubItem || item.node_type === NodeType.NodeSubItem).length === items.length;
        const isGroups = items.filter(item => item.node_type === NodeType.Group).length === items.length;
        const isNodesOrItems = items.filter(item => item.node_type === NodeType.NodeItem || item.node_type === NodeType.Node).length === items.length;

        const treeItems = store.streetTree.treeItems;
        const targetItemId = target.targetType === 'between-items' ? target.parentItem : target.targetItem;
        const targetItem = treeItems[targetItemId];
        console.log('>>canDropAt<<', items, targetItem);

        // sub-items must be moved within/between items (Streets)
        // and Streets' groups (folders in streets)
        if ((isSubItems || isGroups) &&
            (targetItem.node_type === NodeType.NodeItem ||
                targetItem.node_type === NodeType.Group ||
                targetItem.node_type & NodeType.SubItem)) return true;
        // ((targetItem.node_type & NodeType.SubItem) === NodeType.SubItem))) return true;

        // nodes/items must be moved within nodes only
        if (isNodesOrItems && targetItem.node_type === NodeType.Node) return true;

        // no drop
        return false;
    };

    const isNodeSelected = () => focusedItem?.node_type === NodeType.Node;
    const isGroupSelected = () => focusedItem?.node_type === NodeType.Group;
    const isNotSubItemSelected = () => (focusedItem?.node_type & NodeType.SubItem) !== NodeType.SubItem;
    const isNodeSubItemSelected = () => (focusedItem?.node_type & NodeType.NodeSubItem) === NodeType.NodeSubItem;
    const isItemSelected = () => (focusedItem?.node_type & NodeType.Item) === NodeType.Item;

    if (hide) return null;

    return (
        <>
            <div
                onContextMenu={handleContextMenu}
            // style={{ cursor: 'context-menu' }}
            >
                <VmtTree
                    treeId={'vmt-streets-tree'}
                    treeLabel={'StreetsTree'}
                    treeItems={store.streetTree.treeItems}
                    onFocusItem={(item) => { console.log("onFocusItem", item); setFocusedItem(item); }}
                    onDrop={onDrop}
                    onNewItemsDrop={onNewItemsDrop}
                    onRenameItem={onNodeRename}
                    onSelectItems={(items/*, treeId*/) => { setSelectedItems([...items]); }}
                    onDeleteKeyPressed={onMenuItemSelect(ContextMenuItems.DeleteNode)}
                    // onPrimaryAction={onPrimaryAction}
                    canDropAt={canDropAt}
                    canDropOnNonFolder={true}
                    ref={treeRef}
                    color={IconColor}
                    ItemIcon={StreetItemIcon}
                    SubItemIcon={ConstructionSubItemIcon}
                />
            </div>
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={anchorPosition}
            >
                {/* Nodes */}
                {isNodeSelected()
                    &&
                    <MenuItem onClick={onMenuItemSelect(ContextMenuItems.NewNode)} dense>
                        <ListItemIcon>
                            <FolderIcon sx={{ color: IconColor }} />
                        </ListItemIcon>
                        <ListItemText>{ContextMenuItems.NewNode}</ListItemText>
                    </MenuItem>}
                {/* Streets or Groups or NodeSubItem */}
                {(isItemSelected() || isGroupSelected() || isNodeSubItemSelected())
                    &&
                    <MenuItem onClick={onMenuItemSelect(ContextMenuItems.NewGroup)} dense>
                        <ListItemIcon>
                            <FolderIcon sx={{ color: IconColor }} />
                        </ListItemIcon>
                        <ListItemText>{ContextMenuItems.NewGroup}</ListItemText>
                    </MenuItem>}
                {/* Nodes */}
                {isNodeSelected()
                    &&
                    <MenuItem onClick={onMenuItemSelect(ContextMenuItems.NewStreet)} dense>
                        <ListItemIcon>
                            <StreetItemIcon sx={{ color: IconColor }} />
                        </ListItemIcon>
                        <ListItemText>{ContextMenuItems.NewStreet}</ListItemText>
                    </MenuItem>}
                {/* Cannot rename sub item */}
                {isNotSubItemSelected()
                    &&
                    <MenuItem onClick={onMenuItemSelect(ContextMenuItems.RenameNode)} dense>
                        <ListItemIcon>
                            <DriveFileRenameOutlineIcon sx={{ color: IconColor }} />
                        </ListItemIcon>
                        <ListItemText>{ContextMenuItems.RenameNode}</ListItemText>
                    </MenuItem>}
                {/* Applied to all tree items */}
                <MenuItem onClick={onMenuItemSelect(ContextMenuItems.DeleteNode)} dense>
                    <ListItemIcon>
                        <DeleteForeverIcon sx={{ color: IconColor }} />
                    </ListItemIcon>
                    <ListItemText>{ContextMenuItems.DeleteNode}</ListItemText>
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={onMenuItemSelect(ContextMenuItems.NodeDetails)} dense disabled>
                    <ListItemIcon>
                        <InfoIcon sx={{ color: IconColor }} />
                    </ListItemIcon>
                    <ListItemText>{ContextMenuItems.NodeDetails}</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
});