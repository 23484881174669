import { useState, useEffect, useRef, forwardRef } from 'react';
import './BlocklyComponent.css';

import Blockly from 'blockly/core';
import locale from 'blockly/msg/en';
import 'blockly/blocks';

import { useAppContext } from "app-context";
import { Themes } from 'utils/const';
import { useCombinedRefs } from 'utils/misc';

Blockly.setLocale(locale);

// const originalContextMenuFn = Blockly.ContextMenu.show;
// Blockly.ContextMenu.show = (e, menuOptions, rtl) => {
//     console.log('Blockly.ContextMenu.show');
//     originalContextMenuFn(e, menuOptions, rtl);
// };

/*
// Attach event listeners.
Blockly.bindEventWithChecks_(zoomresetSvg, 'mousedown', null, function(e) {
  ws.markFocused();
  ws.setScale(ws.options.zoomOptions.startScale);
  ws.beginCanvasTransition();
  ws.scrollCenter();
  setTimeout(ws.endCanvasTransition.bind(ws), 500);
  Blockly.Touch.clearTouchIdentifier();  // Don't block future drags.
  e.stopPropagation();  // Don't start a workspace scroll.
  e.preventDefault();  // Stop double-clicking from selecting text.
});
 
*/

// !!! MONKEY PATCH
// Change the default healing stack behavior during the block dragging
/**
 * Start a gesture: update the workspace to indicate that a gesture is in
 * progress and bind mousemove and mouseup handlers.
 * @param {!Event} e A mouse down or touch start event.
 * @package
 */
Blockly.Gesture.prototype.doStart = function (e) {
    if (Blockly.browserEvents.isTargetInput(e)) {
        this.cancel();
        return;
    }
    this.hasStarted_ = true;

    Blockly.blockAnimations.disconnectUiStop();
    this.startWorkspace_.updateScreenCalculationsIfScrolled();
    if (this.startWorkspace_.isMutator) {
        // Mutator's coordinate system could be out of date because the bubble was
        // dragged, the block was moved, the parent workspace zoomed, etc.
        this.startWorkspace_.resize();
    }

    // Hide chaff also hides the flyout, so don't do it if the click is in a
    // flyout.
    Blockly.hideChaff(!!this.flyout_);

    this.startWorkspace_.markFocused();
    this.mostRecentEvent_ = e;

    Blockly.Tooltip.block();

    if (this.targetBlock_) {
        this.targetBlock_.select();
    }

    if (Blockly.browserEvents.isRightButton(e)) {
        this.handleRightClick(e);
        return;
    }

    if ((e.type.toLowerCase() === 'touchstart' ||
        e.type.toLowerCase() === 'pointerdown') &&
        e.pointerType !== 'mouse') {
        Blockly.longStart(e, this);
    }

    this.mouseDownXY_ = new Blockly.utils.Coordinate(e.clientX, e.clientY);
    this.healStack_ = !(e.altKey || e.ctrlKey || e.metaKey);

    this.bindMouseEvents(e);
};

// /**
// * Handle the after tree item selected action.
// * @param {Blockly.tree.BaseNode} oldNode The previously selected node.
// * @param {Blockly.tree.BaseNode} newNode The newly selected node.
// * @private
// */
// Blockly.Toolbox.prototype.handleAfterTreeSelected_ = function (oldNode, newNode) {
//     console.log('Blockly.Toolbox.prototype.handleAfterTreeSelected_');
//     console.log(oldNode);
//     console.log(newNode);
//     if (newNode && newNode.blocks && newNode.blocks.length) {
//         this.flyout_.show(newNode.blocks);
//         // Scroll the flyout to the top if the category has changed.
//         if (this.lastCategory_ !== newNode) {
//             this.flyout_.scrollToStart();
//         }
//         if (this.workspace_.keyboardAccessibilityMode) {
//             Blockly.navigation.setState(Blockly.navigation.STATE_TOOLBOX);
//         }
//     } else {
//         // Hide the flyout.
//         this.flyout_.hide();
//         if (this.workspace_.keyboardAccessibilityMode &&
//             !(newNode instanceof Blockly.Toolbox.TreeSeparator)) {
//             Blockly.navigation.setState(Blockly.navigation.STATE_WS);
//         }
//     }
//     if (oldNode !== newNode && oldNode !== this) {
//         var event = new Blockly.Events.Ui(null, 'category',
//             oldNode && oldNode.content, newNode && newNode.content);
//         event.workspaceId = this.workspace_.id;
//         Blockly.Events.fire(event);
//     }
//     if (newNode) {
//         this.lastCategory_ = newNode;
//     }
// };


class VMTConnectionChecker extends Blockly.ConnectionChecker {
    /**
     * Constructor for the connection checker.
     */
    // eslint-disable-next-line no-useless-constructor
    constructor() {
        super();
    }

    /**
     * Type check arrays must either intersect or both be null.
     * @override
     */
    doTypeChecks(a, b) {
        const checkArrayOne = a.getCheck() || ['null', 'String', 'Number'];
        const checkArrayTwo = b.getCheck() || ['null', 'String', 'Number'];
        // console.log(checkArrayOne, checkArrayTwo);

        // Find any intersection in the check lists.
        const intersection = checkArrayOne.filter(x => checkArrayTwo.includes(x));
        if (intersection.length > 0) {
            return true;
        }

        // No intersection.
        return false;
    }
}

const connectionCheckerType = Blockly.registry.Type.CONNECTION_CHECKER;
const vmtConnectionCheckerName = 'VMTConnectionChecker';

// Register the checker so that it can be used by name.
Blockly.registry.register(
    connectionCheckerType,
    vmtConnectionCheckerName,
    VMTConnectionChecker
);

const vmtDarkTheme = Blockly.Theme.defineTheme('vmtThemeDark', {
    'base': Blockly.Themes.Classic,
    // 'blockStyles': blockStyles,
    // 'categoryStyles': categoryStyles,
    // 'componentStyles': componentStyles,
    'componentStyles': {
        'workspaceBackgroundColour': '#1e1e1e',
        'toolboxBackgroundColour': 'blackBackground',
        'toolboxForegroundColour': '#fff',
        'flyoutBackgroundColour': '#252526',
        'flyoutForegroundColour': '#ccc',
        'flyoutOpacity': 1,
        'scrollbarColour': '#797979',
        'insertionMarkerColour': '#fff',
        'insertionMarkerOpacity': 0.3,
        'scrollbarOpacity': 0.4,
        'cursorColour': '#d0d0d0',
        'blackBackground': '#333',
    },
    'fontStyle': {
        "family": 'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
        "weight": "lighter",
        "size": 11
    },
    // 'startHats': true
});

const vmtLightTheme = Blockly.Theme.defineTheme('vmtThemeLight', {
    'base': Blockly.Themes.Classic,
    // 'blockStyles': blockStyles,
    // 'categoryStyles': categoryStyles,
    // 'componentStyles': componentStyles,
    // 'componentStyles': {
    //     'workspaceBackgroundColour': '#1e1e1e',
    //     'toolboxBackgroundColour': 'blackBackground',
    //     'toolboxForegroundColour': '#fff',
    //     'flyoutBackgroundColour': '#252526',
    //     'flyoutForegroundColour': '#ccc',
    //     'flyoutOpacity': 1,
    //     'scrollbarColour': '#797979',
    //     'insertionMarkerColour': '#fff',
    //     'insertionMarkerOpacity': 0.3,
    //     'scrollbarOpacity': 0.4,
    //     'cursorColour': '#d0d0d0',
    //     'blackBackground': '#333',
    // },
    'fontStyle': {
        "family": 'SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace',
        "weight": "lighter",
        "size": 11
    },
    // 'startHats': true
});

export const BlocklyComponent = forwardRef((props, ref) => {
    const { workspaceSetter } = props;
    const { initialXml, children, ...rest } = props;

    const { session: { theme } } = useAppContext();

    const toolbox = useRef(null);
    // const blocklyDiv = React.useRef(null);
    const innerRef = useRef(null);
    const blocklyDiv = useCombinedRefs(ref, innerRef);

    const [workspace, setWorkspace] = useState(null);

    useEffect(() => {
        const primaryWorkspace = Blockly.inject(
            blocklyDiv.current,
            {
                toolbox: toolbox.current,
                plugins: {
                    [connectionCheckerType]: vmtConnectionCheckerName
                },
                ...rest
            },
        );

        // set up a theme
        // primaryWorkspace.setTheme(vmtDarkTheme);
        primaryWorkspace.setTheme(theme.themeName === Themes.Dark ? vmtDarkTheme : vmtLightTheme);


        initialXml && (Blockly.Xml.domToWorkspace(Blockly.utils.xml.textToDom(initialXml), primaryWorkspace));//setXml(initialXml);
        workspaceSetter && workspaceSetter(primaryWorkspace);
        setWorkspace(primaryWorkspace);
        // eslint-disable-next-line
    }, [initialXml, workspaceSetter]);

    useEffect(() => {
        console.log('THEME', theme.themeName);
        // set up a theme
        workspace && workspace.setTheme(theme.themeName === Themes.Dark ? vmtDarkTheme : vmtLightTheme);
    }, [theme.themeName, workspace]);

    // const setXml = (xml) => {
    //     Blockly.Xml.domToWorkspace(Blockly.utils.xml.textToDom(xml), primaryWorkspace);
    // }

    return (
        <>
            <div ref={blocklyDiv} id="blocklyDiv" />
            <xml xmlns="https://developers.google.com/blockly/xml" is="blockly" style={{ display: 'none' }} ref={toolbox}>
                {children}
            </xml>
        </>
    );
});

// export default BlocklyComponent;
