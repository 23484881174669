// React
import { useState, useEffect, useCallback, useRef, memo } from 'react';

import Container from 'react-bootstrap/Container';
import "bootstrap/dist/css/bootstrap.css";

// Blockly
import Blockly from 'blockly/core';
import 'blockly/blocks';
// import { WorkspaceSearch } from '@blockly/plugin-workspace-search';
import { ScrollOptions, ScrollBlockDragger, ScrollMetricsManager } from '@blockly/plugin-scroll-options';


// CSS
import { css } from "aphrodite";

// VMT styles
import styles from 'components/Styles';

// VMT Blockly
import BlocklyComponent from 'components/BlocklyComponent';
import { ToolBox as toolbox } from 'components/BasementComponent/BasementToolbox';
import 'blockly_vmt/blocks/blocks';
import 'blockly_vmt/blocks/block_actions';
import 'blockly_vmt/blocks/if_statement';

import { FloatingButton } from 'components/FloatingControls';
import DialogModal from 'components/Modals/DialogModal';
import { InteractionMode } from 'react-complex-tree';
import VmtTree from 'components/VmtTree';
import { ModeStates, Components, Colors, NodeType } from 'utils/const';

// reactive (RxJS)
import {
    blocklyEventStore, constructionDataStore,
    selectedConstructionInfoStore, brickId2EditStore,
    brickFactoryDataStore,
    dataBusStore, useStore
} from 'stores';

import { useAppContext } from "app-context";
import { /*autorun,*/ reaction } from 'mobx';

const UPDATE_REQUIRED_FIELD = 'updateRequired';
const ME = Components.BASEMENT.key;
const initXml = '<xml/>';

export const BasementComponent = memo(({hide = false}) => {
    console.log('<BasementComponent/>');
    const { api, store } = useAppContext();

    const [blocklyWorkspace, setBlocklyWorkspace] = useState(null);
    const workspaceRef = useRef(null);
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [labelPos, setLabelPos] = useState({ x: -100, y: -100 });
    const [constructionMode, setConstructionMode] = useState({
        state: ModeStates.NEW_STATE,
        touched: false
    });
    const [updateRequired, setUpdateRequired] = useState(false);
    const [origWsXml, setOrigWsXml] = useState();
    const [showSelectNodeModal, setShowSelectNodeModal] = useState(false);
    const [brickRestoreLocationNode, setBrickRestoreLocationNode] = useState(null);
    const [brickRestorePayload, setBrickRestorePayload] = useState(null);
    const currentBlockRef = useRef(null);

    const [constructionInfo/*, setConstructionInfo*/] = useStore(selectedConstructionInfoStore);
    const [, setDataToProcess] = useStore(constructionDataStore);
    const [blocklyEvent, setBlocklyEvent] = useStore(blocklyEventStore);
    const [, setBrickId2Edit] = useStore(brickId2EditStore);
    const [, setBrickDataToProcess] = useStore(brickFactoryDataStore);
    const [dataBus, setDataBusContent] = useStore(dataBusStore);

    // eslint-disable-next-line no-unused-vars
    const highlightCurrentSelection = (block) => {
        const path = block.pathObject.svgPath;
        Blockly.utils.dom.addClass(path, css(styles.highlightedBlock));
    };

    // eslint-disable-next-line no-unused-vars
    const unhighlightCurrentSelection = (block) => {
        const path = block.pathObject.svgPath;
        Blockly.utils.dom.removeClass(path, css(styles.highlightedBlock));
    };

    useEffect(() => {
        workspaceRef.current = blocklyWorkspace;
    }, [blocklyWorkspace]);

    useEffect(() => {
        // const disposerBS = autorun(() => {
        //     console.log('>>> bricksData CHANGED <<<');
        //     // console.log('bricksById', store.brick.bricksById);
        //     // console.log('bricksById', [...store.brick.bricksById]);
        //     const bricksArray = [...store.brick.bricksById];
        //     // if (store.brick.size > -1) {
        //     if (bricksArray.length > 0) {
        //         updateControlLabels(workspaceRef.current);
        //     }
        // });
        const disposer = reaction(
            () => [...store.brick.bricksById],
            () => {
                console.log('>>> bricksData CHANGED <<<');
                updateControlLabels(workspaceRef.current);
                synchAllBricks(workspaceRef.current);
            });

        return () => {
            disposer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);

    const scrollToVisible = useCallback((blockId) => {
        if (!blocklyWorkspace?.isMovable()) {
            // Cannot scroll to block in a non-movable workspace.
            return;
        }
        const block = blocklyWorkspace.getBlockById(blockId);
        if (!block) {
            // could not find the block
            return;
        }
        // XY is in workspace coordinates.
        const xy = block.getRelativeToSurfaceXY();
        const scale = blocklyWorkspace.scale;
        const yAxeOffset = 50 * scale;

        // Block bounds in pixels relative to the workspace origin (0,0 is centre).
        const width = block.width * scale;
        const height = block.height * scale;
        const top = xy.y * scale;
        const bottom = (xy.y + block.height) * scale;
        // In RTL the block's position is the top right of the block, not top left.
        const left = blocklyWorkspace.RTL ? xy.x * scale - width : xy.x * scale;
        const right = blocklyWorkspace.RTL ? xy.x * scale : xy.x * scale + width;

        const metrics = blocklyWorkspace.getMetrics();

        let targetLeft = metrics.viewLeft;
        const overflowLeft = left < metrics.viewLeft;
        const overflowRight = right > metrics.viewLeft + metrics.viewWidth;
        const wideBlock = width > metrics.viewWidth;

        if ((!wideBlock && overflowLeft) || (wideBlock && !blocklyWorkspace.RTL)) {
            // Scroll to show left side of block
            targetLeft = left;
        } else if ((!wideBlock && overflowRight) ||
            (wideBlock && this.workspace_.RTL)) {
            // Scroll to show right side of block
            targetLeft = right - metrics.viewWidth;
        }

        let targetTop = metrics.viewTop;
        const overflowTop = top < metrics.viewTop;
        const overflowBottom = bottom > metrics.viewTop + metrics.viewHeight;
        const tallBlock = height > metrics.viewHeight;

        if (overflowTop || (tallBlock && overflowBottom)) {
            // Scroll to show top of block
            targetTop = top - yAxeOffset;
        } else if (overflowBottom) {
            // Scroll to show bottom of block
            targetTop = bottom - metrics.viewHeight + yAxeOffset;
        }
        if (targetLeft !== metrics.viewLeft || targetTop !== metrics.viewTop) {
            const activeEl = document.activeElement;
            blocklyWorkspace.scroll(-targetLeft, -targetTop);
            // blocklyWorkspace.centerOnBlock(blockId);
            if (activeEl) {
                // Blockly.WidgetDiv.hide called in scroll is taking away focus.
                // TODO: Review setFocused call in Blockly.WidgetDiv.hide.
                activeEl.focus();
            }
        }
        // hightlight the block
        // block.setHighlighted(true);
        // block.select();
        const prevSelectedBlock = currentBlockRef.current;
        // prevSelectedBlock && prevSelectedBlock.setHighlighted(false);
        // prevSelectedBlock && unhighlightCurrentSelection(prevSelectedBlock);
        prevSelectedBlock && prevSelectedBlock.removeSelect();
        // block.setHighlighted(true);
        // highlightCurrentSelection(block);
        block.addSelect();
        currentBlockRef.current = block;
    }, [blocklyWorkspace]);

    useEffect(() => {
        console.log('useEffect [dataBus]', dataBus);
        if (dataBus?.to === ME) {
            switch (dataBus?.from) {
                case Components.CODE_EDITOR.key:
                    if (dataBus?.content?.blockId === null) return;
                    const blockId = dataBus.content.blockId;
                    console.log('Block ID to select', blockId);
                    scrollToVisible(blockId);
                    break;
                default:
                    break;
            }
        }
    }, [dataBus, scrollToVisible]);

    const getBrickData = (vmtBlock) => {
        const vmtBlockId = vmtBlock.data ? JSON.parse(vmtBlock.data).id : vmtBlock.type;
        return store.brick.byId(vmtBlockId) || null;
    };

    const isBrickUpToDate = (vmtBlock) => {
        const brick = getBrickData(vmtBlock);
        console.log('isBrickUpToDate => brickData', brick);

        const dbFields = brick.controls;
        const childBlocks = vmtBlock.getChildren();
        // get the current controls ids in its order
        let controlIds = [];
        if (childBlocks.length > 0 && childBlocks[0].type.startsWith('vmt_control')) {
            const children = childBlocks[0].getDescendants();
            const controlBlocks = children.filter(({ type }) => type.startsWith('vmt_control'));
            controlIds = controlBlocks.map(controlBlock => JSON.parse(controlBlock.data).id);
        }
        if (controlIds.length !== dbFields.length) return false;
        // compare fields description from database with actual controls
        let index = 0;
        return dbFields.every(({ id }) => id === controlIds[index++]);
    };

    const updateControlLabels = (workspace) => {
        if (!workspace) return;
        console.log('updateControlLabels');
        // get VMT blocks
        const allBlocks = workspace.getAllBlocks(true);
        const vmtBlocks = allBlocks.filter(block => block.type === 'vmt_brick');

        vmtBlocks.forEach((vmtBlock) => {
            const brick = getBrickData(vmtBlock);
            if (brick === null) { // original brick is missing (was deleted)
                vmtBlock.setHighlighted(true);
                vmtBlock.setWarningText('The brick was deleted');
                vmtBlock.setColour('#FF1111');
                vmtBlock['vmt_deleted_'] = true;
            }
            else {
                // update restored brick
                if (vmtBlock?.vmt_deleted_ === true) {
                    vmtBlock.setHighlighted(false);
                    vmtBlock.setWarningText(null);
                    vmtBlock.setColour(Colors.BRICK_COLOR);
                    delete vmtBlock['vmt_deleted_'];
                }

                // update labels if required
                if (!isBrickUpToDate(vmtBlock)) {
                    vmtBlock.setHighlighted(true);
                    vmtBlock.setWarningText('The newer version of the brick is available, click "Update" to synchronize the changes.');
                    vmtBlock[UPDATE_REQUIRED_FIELD] = true;
                    console.log('warning brick', vmtBlock);
                    setUpdateRequired(prevState => prevState || true);
                }

                const childBlocks = vmtBlock.getChildren();
                // get list of controls for the block
                let controlBlocks = [];
                if (childBlocks.length > 0 && childBlocks[0].type.startsWith('vmt_control')) {
                    const children = childBlocks[0].getDescendants();
                    controlBlocks = children.filter(({ type }) => type.startsWith('vmt_control'));
                }
                // synch controls' label values with actual from database
                controlBlocks.forEach(control => {
                    const ctrlId = JSON.parse(control.data).id;
                    const dbField = brick.controls.find(ctrl => ctrl.id === ctrlId)
                    const currentControlName = control.getFieldValue('CONTROL_NAME').trim();
                    if (dbField && currentControlName !== dbField.name) {
                        control.setFieldValue(dbField.name, 'CONTROL_NAME');
                    }
                });
                // TODO: there must be a way to improve the logic!!
                // align the text in controls
                const maxFieldLength = controlBlocks.reduce((maxLength, control) => {
                    const controlNameLength = control.getFieldValue('CONTROL_NAME').trim().length;
                    return Math.max(maxLength, controlNameLength)
                }, 0);
                // align the controls' lengths
                controlBlocks.forEach(control => {
                    const currentControlName = control.getFieldValue('CONTROL_NAME').trim();
                    const paddedControlName = currentControlName.padEnd(maxFieldLength);
                    control.setFieldValue(paddedControlName, 'CONTROL_NAME');
                });
            }
        });
    };

    const loadXmlToWorkspace = (wsXml) => {
        Blockly.Events.disable();
        try {
            blocklyWorkspace.trashcan.emptyContents();
            const xml = Blockly.utils.xml.textToDom(wsXml);
            Blockly.Xml.clearWorkspaceAndLoadFromXml(xml, blocklyWorkspace);
            blocklyWorkspace.trashcan.emptyContents();
            const currentWsXml = Blockly.Xml.workspaceToDom(blocklyWorkspace);
            setOrigWsXml(Blockly.Xml.domToText(currentWsXml));
            setConstructionMode((mode) => ({ ...mode, touched: false }));
        }
        finally {
            Blockly.Events.enable();
            blocklyWorkspace.fireChangeListener(new (Blockly.Events.get(Blockly.Events.FINISHED_LOADING))());
            // Blockly.Events.fire(new (Blockly.Events.get(Blockly.Events.FINISHED_LOADING))());
        }

    };

    const loadConstruction = async (constructionId) => {
        console.log('loadConstruction', constructionId);
        setUpdateRequired(false);
        if (constructionId === null) {
            // // Empty (prev.: New) construction
            // console.log('Load new construction');
            loadXmlToWorkspace(initXml);
        }
        else {
            // Load construction form DB
            console.log(`Load construction form DB; constr. id: `, constructionId);
            const constructionXml = await api.construction.getOne(constructionId.id);
            loadXmlToWorkspace(constructionXml);
            updateControlLabels(blocklyWorkspace);
        }
    };

    const saveConstruction = useCallback(() => {
        console.log('saveConstruction')
        const xmlDom = Blockly.Xml.workspaceToDom(blocklyWorkspace);
        console.log(xmlDom);
        const blocks = blocklyWorkspace.getTopBlocks(false);
        let baseTestBlock = blocks.find(block => block.type === 'base_test');

        // get VMT blocks
        const allBlocks = blocklyWorkspace.getAllBlocks(true);
        const vmtBricks = allBlocks.filter((block) => block.type === 'vmt_brick')
        const vmtBrickIds = [...new Set(vmtBricks.map(vmtBrick => JSON.parse(vmtBrick.data).id))];
        console.log('Used bricks', vmtBrickIds);

        // get list of controls with action(s) assigned to them
        let actionControls = {};
        vmtBricks.forEach((brick) => {
            const childBlocks = brick.getChildren();
            // get list of controls for the block
            let controls = [];
            if (childBlocks.length > 0 && childBlocks[0].type.startsWith('vmt_control')) {
                const children = childBlocks[0].getDescendants();
                controls = children.filter(({ type }) => type.startsWith('vmt_control'));
            }
            // check for actions
            const actControls = controls.reduce((actionControls, ctrlBlock) => {
                if (ctrlBlock.getInputTargetBlock('ACTION')) {
                    const controlData = JSON.parse(ctrlBlock.data);
                    actionControls[controlData.id] = true;
                }
                return actionControls;
            }, {});
            actionControls = { ...actionControls, ...actControls };
        });
        const actionControlIds = Object.keys(actionControls);
        console.log('Controls with actions', actionControlIds);

        setDataToProcess({
            workspace_id: baseTestBlock.id,
            workspace_name: baseTestBlock.getFieldValue('TEST_NAME').trim(),
            workspace_xml: Blockly.Xml.domToText(xmlDom),
            bricks: vmtBrickIds,
            controls: actionControlIds
        });

        setOrigWsXml(Blockly.Xml.domToText(xmlDom));
        setConstructionMode({
            state: ModeStates.EDIT_STATE,
            touched: false
        });

    }, [blocklyWorkspace, setDataToProcess, setConstructionMode]);

    const synchBrick = (vmtBlock) => {
        console.log('synchBrick')
        const brick = getBrickData(vmtBlock);
        const brickControls = brick.controls;
        const workspace = vmtBlock.workspace;

        // get list of controls for the block
        const childBlocks = vmtBlock.getChildren();
        let controls = [];
        if (childBlocks.length > 0 && childBlocks[0].type.startsWith('vmt_control')) {
            const children = childBlocks[0].getDescendants();
            controls = children.filter(({ type }) => type.startsWith('vmt_control'));
        }
        const actions = {};
        console.log('controls (before unplug): ', controls);
        controls.forEach(control => {
            // detach controls
            control.unplug();
            control.setParent(null);
            const actionBlock = control.getInputTargetBlock('ACTION');
            if (actionBlock) {
                const controlData = JSON.parse(control.data);
                actions[controlData.id] = actionBlock;
                // detach action block
                actionBlock.unplug();
                actionBlock.setParent(null);
            }
        });

        // dispose current control blocks
        controls.forEach(control => {
            control.dispose();
        });

        console.log('actions: ', actions);
        const actionControlIds = Object.keys(actions);
        let parentConnection = vmtBlock.getInput('CONTROLS').connection;
        brickControls.forEach(({ id, anchor }, index, arr) => {
            // create new control block
            let controlBlock
            if (index === arr.length - 1) {
                controlBlock = workspace.newBlock('vmt_control_last');
            }
            else {
                controlBlock = workspace.newBlock('vmt_control');
            }
            controlBlock.data = JSON.stringify({ id: id, ID: anchor });
            // deletable="false" movable="false"
            controlBlock.setDeletable(false);
            controlBlock.setMovable(false);

            // connect to the brick or prev. control
            const childConnection = controlBlock.previousConnection;
            parentConnection.connect(childConnection);
            parentConnection = controlBlock.nextConnection;
            controlBlock.initSvg();
            controlBlock.render();
            // connect action if required
            if (actionControlIds.includes(id)) {
                const actionConnection = actions[id].outputConnection;
                const ctrlActionConnection = controlBlock.getInput('ACTION').connection;
                ctrlActionConnection.connect(actionConnection);
            }
        })
    };

    const reloadWorkspace = (workspace) => {
        console.log('reloadWorkspace')
        const xml = Blockly.Xml.workspaceToDom(workspace);
        Blockly.Xml.clearWorkspaceAndLoadFromXml(xml, workspace);
        updateControlLabels(workspace);
    };

    const synchAllBricks = (workspace) => {
        console.log('synchAllBricks')
        if (workspace === null) return;

        const allBlocks = workspace.getAllBlocks(true);
        const vmtBricks = allBlocks.filter(block => block.type === 'vmt_brick');
        vmtBricks.forEach(brick => {
            if (UPDATE_REQUIRED_FIELD in brick) {
                console.log('Brick to update: ', brick);
                synchBrick(brick);
            }
        })
        // re-load current construction;
        reloadWorkspace(workspace);
        // mark the construction as up-to-date
        setUpdateRequired(false);
    };


    // restore brick(s)
    const closeModal = () => setShowSelectNodeModal(false);
    const showModal = () => setShowSelectNodeModal(true);

    const updateBrickRestoreLocation = (nodeItem) => {
        console.log('updateBrickRestoreLocation', nodeItem);
        setBrickRestoreLocationNode(nodeItem);
    }

    const restoreBrick = useCallback(() => {
        console.log('restoreBrick')
        // // brickFactoryDataStore.setData
        setBrickDataToProcess({
            mode: //brickMode,
            {
                state: ModeStates.NEW_STATE,
                touched: false
            },
            nodeId: brickRestoreLocationNode, //nodeIdToStore,
            brickId: null, // does not require when create new brick
            dataToStore: brickRestorePayload
        });
        closeModal();
    }, [brickRestoreLocationNode, brickRestorePayload, setBrickDataToProcess]);

    const getVmtBrick = (selectedBlock) => {
        if (selectedBlock && selectedBlock.type === 'vmt_brick') {
            return selectedBlock;
        }
        else {
            if (selectedBlock && selectedBlock.type.startsWith('vmt_control')) {
                return selectedBlock.getSurroundParent();
            }
        }
        return null;
    };

    const registerBlocklyContextMenuOptions = useCallback(() => {
        const restoreBrick = (vmtBrick) => {
            console.log('restoreBrick', vmtBrick);
            const vmtBrickData = {
                id: JSON.parse(vmtBrick.data).id,
                brick_caption: vmtBrick.getFieldValue('brick_caption'),
            };
            const childBlocks = vmtBrick.getChildren();
            // get the current controls ids in its order
            let controlsData = [];
            if (childBlocks.length > 0 && childBlocks[0].type.startsWith('vmt_control')) {
                const children = childBlocks[0].getDescendants();
                const controlBlocks = children.filter(({ type }) => type.startsWith('vmt_control'));
                controlsData = controlBlocks.map(controlBlock => {
                    return {
                        _id: JSON.parse(controlBlock.data).id,
                        name: controlBlock.getFieldValue('CONTROL_NAME').trim(),
                        ID: JSON.parse(controlBlock.data).ID,
                    };
                });
            }
            console.log('vmtBrickId', vmtBrickData);
            console.log('controls', controlsData);

            // create new brick payload
            const dbBrickJson = {};
            dbBrickJson._id = vmtBrickData.id;
            dbBrickJson.brick_type = vmtBrickData.brick_caption;
            dbBrickJson.brick_caption = vmtBrickData.brick_caption;

            // form controls array
            dbBrickJson.controls = controlsData;
            console.log(dbBrickJson);
            setBrickRestorePayload(dbBrickJson);
            showModal();
        };

        const workspaceRestoreItem = {
            displayText: 'Restore Deleted Bricks',
            preconditionFn: function (scope) {
                const allBlocks = scope.workspace.getAllBlocks(true);
                // try to find at least one deleted brick in the workspace
                const vmtBrick = allBlocks.find(block => block.type === 'vmt_brick' && block['vmt_deleted_'] && block['vmt_deleted_'] === true);
                return vmtBrick !== undefined ? 'enabled' : 'hidden';
            },
            callback: function (scope) {
                console.log('workspace callback', scope);
            },
            scopeType: Blockly.ContextMenuRegistry.ScopeType.WORKSPACE,
            id: 'restore_brick_workspace',
            weight: 100,
        };
        if (Blockly.ContextMenuRegistry.registry.getItem(workspaceRestoreItem.id) !== null) {
            Blockly.ContextMenuRegistry.registry.unregister(workspaceRestoreItem.id);
        }
        Blockly.ContextMenuRegistry.registry.register(workspaceRestoreItem);

        // menu item for VMT brick (Restore Brick)
        let brickRestoreItem = { ...workspaceRestoreItem };
        brickRestoreItem.displayText = 'Restore Deleted Brick'
        brickRestoreItem.scopeType = Blockly.ContextMenuRegistry.ScopeType.BLOCK;
        brickRestoreItem.id = 'restore_brick_block';
        brickRestoreItem.preconditionFn = function (scope) {
            let vmtBrick = getVmtBrick(scope.block);
            if (vmtBrick && vmtBrick['vmt_deleted_'] && vmtBrick['vmt_deleted_'] === true) {
                return 'enabled';
            }
            return 'hidden';
        };
        brickRestoreItem.callback = function (scope) {
            console.log('brick callback', scope);
            restoreBrick(getVmtBrick(scope.block));
        };
        if (Blockly.ContextMenuRegistry.registry.getItem(brickRestoreItem.id) !== null) {
            Blockly.ContextMenuRegistry.registry.unregister(brickRestoreItem.id);
        }
        Blockly.ContextMenuRegistry.registry.register(brickRestoreItem);

        // menu item for VMT brick (Jump TO Brick)
        let brickJumpToItem = { ...workspaceRestoreItem };
        brickJumpToItem.displayText = 'Jump To Brick'
        brickJumpToItem.scopeType = Blockly.ContextMenuRegistry.ScopeType.BLOCK;
        brickJumpToItem.id = 'jump_to_brick';
        brickJumpToItem.preconditionFn = function (scope) {
            let vmtBrick = getVmtBrick(scope.block);
            if (vmtBrick && vmtBrick?.vmt_deleted_ !== true) {
                return 'enabled';
            }
            return 'hidden';
        };
        brickJumpToItem.callback = function (scope) {
            const vmtBrick = getVmtBrick(scope.block);
            const brickId = JSON.parse(vmtBrick.data).id
            console.log('Jump To brickId', brickId);
            setDataBusContent({ to: Components.BRICK_TREE.key, from: ME, content: { brickId } })
        };
        if (Blockly.ContextMenuRegistry.registry.getItem(brickJumpToItem.id) !== null) {
            Blockly.ContextMenuRegistry.registry.unregister(brickJumpToItem.id);
        }
        Blockly.ContextMenuRegistry.registry.register(brickJumpToItem);
    }, [setDataBusContent]);

    const invokeBlocklyMenuItem = (blocklyMenuItem, scope) => {
        if (blocklyMenuItem.preconditionFn(scope)) {
            blocklyMenuItem.callback(scope);
            return true;
        }
        return false;
    };

    const registerShortcuts = useCallback((workspace /* ?? */) => {
        const jumpToBrickShortcut = {
            name: 'jumpToBrickShortcut',
            blocklyMenuItem: Blockly.ContextMenuRegistry.registry.getItem('jump_to_brick'),
            preconditionFn: function (/*workspace*/) {
                const jumpToBrickItem = this.blocklyMenuItem;
                if (selectedBlock && jumpToBrickItem &&
                    jumpToBrickItem.preconditionFn({ block: selectedBlock }) === 'enabled') {
                    return true;
                }
                return false;
            },
            callback: function (/*workspace*/) {
                const jumpToBrickItem = this.blocklyMenuItem;
                console.log('>>> jumpToBrickShortcut <<<', jumpToBrickItem);
                if (invokeBlocklyMenuItem(jumpToBrickItem, { block: selectedBlock })) {
                    return true;
                }
                return false;
            }
        };

        const restoreBrickShortcut = {
            name: 'restoreBrickShortcut',
            blocklyMenuItem: Blockly.ContextMenuRegistry.registry.getItem('restore_brick_block'),
            preconditionFn: function (/*workspace*/) {
                const restoreBrickItem = this.blocklyMenuItem;
                if (selectedBlock && restoreBrickItem &&
                    restoreBrickItem.preconditionFn({ block: selectedBlock }) === 'enabled') {
                    return true;
                }
                return false;
            },
            callback: function (/*workspace*/) {
                const restoreBrickItem = this.blocklyMenuItem;
                console.log('>>> restoreBrickShortcut  <<<', restoreBrickItem);
                if (invokeBlocklyMenuItem(restoreBrickItem, { block: selectedBlock })) {
                    return true;
                }
                return false;
            }
        };

        if (workspace) {
            // Register 'jump to brick' shortcut
            Blockly.ShortcutRegistry.registry.removeAllKeyMappings(jumpToBrickShortcut.name);
            Blockly.ShortcutRegistry.registry.register(jumpToBrickShortcut, true);
            const keyJ = Blockly.ShortcutRegistry.registry.createSerializedKey(
                Blockly.utils.KeyCodes.J/*, [Blockly.ShortcutRegistry.modifierKeys.Control]*/);
            Blockly.ShortcutRegistry.registry.addKeyMapping(keyJ, jumpToBrickShortcut.name);

            // Register 'restore brick' shortcut
            Blockly.ShortcutRegistry.registry.removeAllKeyMappings(restoreBrickShortcut.name);
            Blockly.ShortcutRegistry.registry.register(restoreBrickShortcut, true);
            const keyR = Blockly.ShortcutRegistry.registry.createSerializedKey(
                Blockly.utils.KeyCodes.R/*, [Blockly.ShortcutRegistry.modifierKeys.Control]*/);
            Blockly.ShortcutRegistry.registry.addKeyMapping(keyR, restoreBrickShortcut.name);
        }
    }, [selectedBlock]);

    // useEffect(() => {
    //     // resiser for proper position calculation of "floating label"
    //     const handleResize = (event) => {
    //         console.log('[Bas] handleResize', event);
    //         if (blocklyWorkspace === null) return;
    //         const rect = blocklyWorkspace.getInjectionDiv().getBoundingClientRect();
    //         console.log('InjectionDiv', blocklyWorkspace.getInjectionDiv());
    //         console.log('rect', rect);
    //         const blocklySvg = blocklyWorkspace.getParentSvg();
    //         blocklySvg.setAttribute('width', `${rect.width}px`);
    //         blocklySvg.setAttribute('height', `${rect.height}px`);
    //         setLabelPos({ x: rect.x + rect.width - 20, y: rect.y + 10 });
    //     }
    //     window.addEventListener('resize', handleResize)
    //     // "unmounters"
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, [blocklyWorkspace]);

    useEffect(() => {
        registerBlocklyContextMenuOptions();
        registerShortcuts(blocklyWorkspace);
    }, [blocklyWorkspace, registerBlocklyContextMenuOptions, registerShortcuts]);

    // useEffect(() => {
    //     console.log('useEffect');
    //     if (blocklyWorkspace === null ||
    //         updatedBrick === null) return;

    //     const allBlocks = blocklyWorkspace.getAllBlocks(true);
    //     const vmtBlocks = allBlocks.reduce((vmtBricks, block) => {
    //         if (block.type === 'vmt_brick') {
    //             const vmtBrickId = block.data ? JSON.parse(block.data).id : block.type;
    //             if (vmtBrickId in vmtBricks) {
    //                 vmtBricks[vmtBrickId].push(block);
    //             }
    //             else {
    //                 vmtBricks[vmtBrickId] = [block];
    //             }
    //         }
    //         return vmtBricks;
    //     }, {});
    //     const vmtBrickIds = Object.keys(vmtBlocks);

    //     if (vmtBrickIds.includes(updatedBrick)) {
    //         console.log('>>>UPDATE REQUIRED<<<');
    //         vmtBlocks[updatedBrick].forEach(brick => {
    //             // skip 'old' bricks for now 
    //             // and update only up-to-dated bricks
    //             if (!(UPDATE_REQUIRED_FIELD in brick)) {
    //                 console.log('Brick to update: ', brick);
    //                 synchBrick(brick);
    //             }
    //         })
    //         // re-load current construction;
    //         reloadWorkspace(blocklyWorkspace);
    //     }
    //     const xmlDom = Blockly.Xml.workspaceToDom(blocklyWorkspace);
    //     console.log(xmlDom);
    //     // mark the data as consumed
    //     setUpdatedBrick(null);
    // }, [updatedBrick, blocklyWorkspace, setUpdatedBrick]);

    useEffect(() => {
        console.log('constructionInfo: ', constructionInfo);
        if (blocklyWorkspace !== null) {
            loadConstruction(constructionInfo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blocklyWorkspace, constructionInfo/*, loadConstruction*/]);

    const variablesFlyoutCallback = function (workspace) {
        const variableModelList = workspace.getVariablesOfType('');
        //const variables = Blockly.Variables.allUsedVarModels(workspace);
        var button = document.createElement('button');
        button.setAttribute('text', '%{BKY_NEW_VARIABLE}');
        button.setAttribute('callbackKey', 'CREATE_VARIABLE');

        workspace.registerButtonCallback('CREATE_VARIABLE', function (button) {
            Blockly.Variables.createVariableButtonHandler(button.getTargetWorkspace());
        });

        let xmlList = [];
        xmlList.push(button);

        if (variableModelList.length > 0) {
            // New variables are added to the end of the variableModelList.
            var mostRecentVariable = variableModelList[variableModelList.length - 1];
            let block;
            if (Blockly.Blocks['vmt_variables_set']) {
                block = Blockly.utils.xml.createElement('block');
                block.setAttribute('type', 'vmt_variables_set');
                block.setAttribute('gap', Blockly.Blocks['math_change'] ? 8 : 24);
                block.appendChild(
                    Blockly.Variables.generateVariableFieldDom(mostRecentVariable));
                xmlList.push(block);
            }
            /*
            if (Blockly.Blocks['math_change']) {
                var block = Blockly.utils.xml.createElement('block');
                block.setAttribute('type', 'math_change');
                block.setAttribute('gap', Blockly.Blocks['variables_get'] ? 20 : 8);
                block.appendChild(
                    Blockly.Variables.generateVariableFieldDom(mostRecentVariable));
                var value = Blockly.utils.xml.textToDom(
                    '<value name="DELTA">' +
                    '<shadow type="math_number">' +
                    '<field name="NUM">1</field>' +
                    '</shadow>' +
                    '</value>');
                block.appendChild(value);
                xmlList.push(block);
            }
            */
            if (Blockly.Blocks['vmt_variables_get']) {
                variableModelList.sort(Blockly.VariableModel.compareByName);
                for (var i = 0, variable; (variable = variableModelList[i]); i++) {
                    block = Blockly.utils.xml.createElement('block');
                    block.setAttribute('type', 'vmt_variables_get');
                    block.setAttribute('gap', 8);
                    block.appendChild(Blockly.Variables.generateVariableFieldDom(variable));
                    xmlList.push(block);
                }
            }
        }
        return xmlList;
    };

    useEffect(() => {
        console.log('useEffect');
        if (blocklyWorkspace === null) return;

        // register VMT variable flyout
        blocklyWorkspace.registerToolboxCategoryCallback('VMT_VARIABLE', variablesFlyoutCallback);

        const resizeObserver = new ResizeObserver(([entry]) => {
            const rect = entry.target.getBoundingClientRect();
            setLabelPos({ x: rect.x + rect.width - 40, y: rect.y + 50 });
        });
        resizeObserver.observe(blocklyWorkspace.getInjectionDiv());

        // // worspace search
        // const workspaceSearch = new WorkspaceSearch(blocklyWorkspace);
        // workspaceSearch.init();

        // Initialize scrolling plugin
        const plugin = new ScrollOptions(blocklyWorkspace);
        plugin.init();

        return () => {
            resizeObserver.disconnect();
        };
    }, [blocklyWorkspace]);

    useEffect(() => {
        if (blocklyWorkspace) {
            const currentWsXml = Blockly.Xml.workspaceToDom(blocklyWorkspace);
            const currentWsXmlText = Blockly.Xml.domToText(currentWsXml);
            // console.log('curr', currentWsXmlText);
            // console.log('orig', origWsXml);
            if (currentWsXmlText !== origWsXml) {
                // console.log('currentWsXmlText !== origWsXml');
                setConstructionMode((mode) => ({ ...mode, touched: true }));
            }
            else {
                // console.log('currentWsXmlText === origWsXml');
                setConstructionMode((mode) => ({ ...mode, touched: false }));
            }
        }
    }, [blocklyEvent, blocklyWorkspace, origWsXml]);

    // useEffect(() => {
    //     console.log('constructionMode', constructionMode);
    // }, [constructionMode]);

    // Blockly event listener
    useEffect(() => {
        console.log('useEffect');
        if (blocklyWorkspace !== null) {
            let clickTimeStamp_ = 0;
            const workspaceChange = (event) => {
                // to avoid 'bombarding' with Blockly events we skip VIEWPORT_CHANGE
                if (event.type === Blockly.Events.VIEWPORT_CHANGE) return;

                setBlocklyEvent({ blocklyWorkspace: blocklyWorkspace, blocklyEvent: event });
                // console.log('workspaceChange', event);

                // delete all newly ceated blocks in case no construction is loaded
                if (event.type === Blockly.Events.CREATE) {
                    console.log('workspaceChange', event);
                    const workspace = Blockly.Workspace.getById(event.workspaceId);
                    const blocks = workspace.getTopBlocks(false);
                    const baseTestBlock = blocks.find(block => block.type === 'base_test');
                    if (baseTestBlock === undefined) {
                        const block = workspace.getBlockById(event.blockId);
                        block.dispose();
                    }
                }
                if (event.type === Blockly.Events.SELECTED) {
                    const workspace = Blockly.Workspace.getById(event.workspaceId);
                    event.newElementId &&
                        setDataBusContent({
                            to: Components.V_APP.key,
                            from: ME,
                            content: {
                                blockId: event.newElementId
                            }
                        });
                    const block = workspace.getBlockById(event.newElementId);
                    // console.log('block:', block);
                    // check if it is a VMT brick
                    if (block && block.type === 'vmt_brick') {
                        const brickId = JSON.parse(block.data).id;
                        setBrickId2Edit(brickId);
                        setSelectedBlock(block);
                    }
                    else {
                        if (block && block.type.startsWith('vmt_control')) {
                            const parentBlock = block.getSurroundParent();
                            // parentBlock.select();
                            const brickId = JSON.parse(parentBlock.data).id;
                            setBrickId2Edit(brickId);
                            setSelectedBlock(parentBlock);
                        }
                        else {
                            setSelectedBlock(null);
                        }
                    }
                }
                // catering for double click
                else if (event.type === Blockly.Events.CLICK) {
                    let clickTimeStamp = Date.now();
                    if (clickTimeStamp_ !== 0) {
                        clickTimeStamp_ = 0;
                        const workspace = Blockly.Workspace.getById(event.workspaceId);
                        console.log(workspace);
                        const block = workspace.getBlockById(event.blockId);
                        // console.log(block, block.isCollapsed());
                        // toggle the block collapse state
                        block && block.setCollapsed(!block.isCollapsed());
                        // console.log(block, block.isCollapsed());
                        console.log("delta");
                    }
                    else {
                        const workspace = Blockly.Workspace.getById(event.workspaceId);
                        const block = workspace.getBlockById(event.blockId);
                        // toggle the block collapse state
                        block && block.isCollapsed() && block.setCollapsed(false);

                        clickTimeStamp_ = clickTimeStamp;
                        setTimeout(() => { clickTimeStamp_ = 0; }, 300);
                    }
                    setTimeout(() => {
                        // hide all warning and comment pop-ups
                        // TODO: should be available with new Blockly version
                        // blocklyWorkspace.hideChaff();
                        const allBlocks = blocklyWorkspace.getAllBlocks(true);
                        const vmtBricks = allBlocks.filter(block => block.type === 'vmt_brick');
                        vmtBricks.forEach(brick => {
                            brick.warning && brick.warning.setVisible(false);
                            brick.comment && brick.comment.setVisible(false);
                        });
                    }, 100);
                }
            };
            blocklyWorkspace.addChangeListener(workspaceChange);
            blocklyWorkspace.addChangeListener(Blockly.Events.disableOrphans);
        }
    }, [blocklyWorkspace, setBlocklyEvent, setBrickId2Edit, setDataBusContent]);

    if (hide) return null;
    
    return (
        <>
            <BlocklyComponent
                workspaceSetter={setBlocklyWorkspace}
                readOnly={false}
                trashcan={true}
                media={'media/'}
                horizontalLayout={false}
                toolboxPosition='start'
                grid={{
                    spacing: 25,
                    length: 3,
                    colour: '#ccc',
                    snap: true
                }}
                move={{
                    scrollbars: true,
                    drag: true,
                    wheel: true
                }}
                zoom={{
                    controls: true,
                    wheel: true,
                    startScale: 0.8,
                    maxScale: 4,
                    minScale: 0.25,
                    scaleSpeed: 1.1
                }}
                maxInstances={{ 'base_test': 1 }}
                renderer={'zelos'}
                initialXml={initXml}
            plugins={{
                // These are both required.
                'blockDragger': ScrollBlockDragger,
                'metricsManager': ScrollMetricsManager,
            }}
            >
                {toolbox}
            </BlocklyComponent>
            {(constructionInfo !== null && constructionMode.touched === true) &&
                (<FloatingButton
                    x={labelPos.x}
                    y={labelPos.y}
                    text='Save'
                    onClick={() => { saveConstruction() }}
                />)
            }
            {/* {constructionMode.state === ModeStates.EDIT_STATE &&
                (<FloatingButton
                    x={labelPos.x}
                    y={constructionMode.touched === true ? labelPos.y + 35 : labelPos.y}
                    text='New'
                    onClick={() => { setConstructionInfo(null) }}
                />)
            } */}
            {updateRequired &&
                (<FloatingButton
                    x={labelPos.x}
                    y={labelPos.y + 35}
                    text='Update'
                    onClick={() => { synchAllBricks(workspaceRef.current) }}
                />)
            }
            <DialogModal
                title='Choose Location'
                show={showSelectNodeModal}
                handleClose={() => { closeModal() }}
                primaryBtnLabel='Select'
                onPrimary={restoreBrick}
            >
                <Container className={`${css(styles.modalScrollingPane)} ${css(styles.appContainer)}`}>
                    <VmtTree
                        treeId={'vmt-bricks-dir-nodes-tree'}
                        treeLabel={'BrickRestoreTree'}
                        defaultInteractionMode={InteractionMode.ClickArrowToExpand}
                        itemArray={store.brickTree.nodes.filter(node => node.node_type === NodeType.Node)}
                        onFocusItem={updateBrickRestoreLocation}
                    />
                </Container>
            </DialogModal>
        </>
    );
});
